import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Header from "./header"
import Footer from "./footer"

export default ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(
            filter: { fileAbsolutePath: { glob: "**/src/markdown-pages/*.md" } }
            sort: { order: ASC, fields: [frontmatter___path] }
          ) {
            edges {
              node {
                id
                frontmatter {
                  categoryName
                  categoryID
                  path
                }
              }
            }
          }
        }
      `}
      render={data => (
        <>
          <Header siteTitle={"data.site.siteMetadata.title"} data={data} />
          <main>{children}</main>
          <Footer />
        </>
      )}
    />
  )
}
