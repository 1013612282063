import React from "react";
// import Image from "../../image"
import { Link } from "gatsby";
import logo from "../../../images/logo.png";

const MainFooter = () => {
  return (
    <div className="container-fluid">
      <div className="row footer-main">
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-section-first column-section ">
          <img
            className="card-logo"
            alt="logo"
            src={logo}
            style={{ filter: "invert(360%)" }}
          />

          <div className="col-body col-body-1">
            <p>
              Vetementco is always focusing on bringing educated buyers and
              sellers together at the time they are ready to do business
            </p>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-2 column-section second-col">
          <div className="col-heading">
            <p className="Links-heading">Quick Links</p>
          </div>
          <div className="col-body col-body-2">
            <p>
              <Link to="/">Categories</Link>
              <br></br>
              <Link to="/privacy-policy">Privacy Policy</Link>
              <br></br>
              <Link to="/terms-and-conditions">Terms & Conditions</Link>
            </p>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 column-section info-col">
          <div className="col-heading">
            <p className="Info-heading">Info</p>
          </div>
          <div className="col-body">
            <svg
              className="footer-col-icon"
              width="14"
              height="15"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 0L0 5.09004L3.80625 8.89766L11.2 2.8L5.10234 10.1937L8.90996 14L14 0Z"
                fill="white"
              />
            </svg>
            <a href="mailto:info@Vetementco.com">info@Vetementco.com</a>
            <p className="mt-2 font-italic">
              VILLAGE RAUGHAN, P.O BOX, Sialkot , SAMBRIAL, Sialkot-51310,
              Pakistan
            </p>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-2 column-section find-us-col">
          <div className="col-heading">
            <p className="find-us-heading">Find us</p>
          </div>
          <div className="col-body">
            <p>
              <svg
                className="footer-col-icon"
                width="14"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 10.9C1 10.9 1.1 10.9 1 10.9C1.1 10.9 1 10.9 1 10.9ZM10 0C4.5 0 0 4.5 0 10C0 15 3.7 19.2 8.6 19.9V12.4H6.4V10H8.7V8.4C8.7 5.8 9.9 4.7 12.1 4.7C13.1 4.7 13.6 4.8 13.9 4.8V6.8H12.4C11.5 6.8 11.2 7.7 11.2 8.7V10H13.9L13.5 12.4H11.2V19.9C16.1 19.3 20 15.1 20 10C20 4.5 15.5 0 10 0Z"
                  fill="white"
                />
              </svg>
              <a href="https://www.facebook.com/Vetement-corporation-120629125335751/">
                @Vetementco
              </a>
              <br></br>
              <svg
                className="footer-col-icon"
                width="14"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.7778 0H2.22222C1 0 0 1 0 2.22222V17.7778C0 19 1 20 2.22222 20H17.7778C19 20 20 19 20 17.7778V2.22222C20 1 19 0 17.7778 0ZM6.66667 15.5556H3.88889V7.77778H6.66667V15.5556ZM5.22222 6.33333C4.33333 6.33333 3.77778 5.77778 3.77778 5C3.77778 4.22222 4.33333 3.66667 5.33333 3.66667C6.22222 3.66667 6.77778 4.22222 6.77778 5C6.77778 5.77778 6.22222 6.33333 5.22222 6.33333ZM16.6667 15.5556H14V11.3333C14 10.1111 13.2222 9.88889 13 9.88889C12.7778 9.88889 11.7778 10.1111 11.7778 11.3333C11.7778 11.5556 11.7778 15.5556 11.7778 15.5556H9V7.77778H11.7778V8.88889C12.1111 8.22222 12.8889 7.77778 14.2222 7.77778C15.5556 7.77778 16.6667 8.88889 16.6667 11.3333V15.5556Z"
                  fill="white"
                />
              </svg>
              <a href="https://www.linkedin.com/">@Vetementco</a>
              <br></br>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainFooter;
