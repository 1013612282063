import React from "react"
import FooterEnding from "./footerComponents/footerEnding"
import MainFooter from "./footerComponents/mainFooter"

const Footer = () => {
  return (
    <>
      <MainFooter />
      <FooterEnding />
    </>
  )
}

export default Footer
