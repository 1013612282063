import React, { useState } from "react"
import { Link } from "gatsby"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Container,
  Dropdown,
  DropdownToggle,
  Row,
  Col,
} from "reactstrap"

import Image from "../image"

const Header = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const dropdownRef = React.useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)
  const toggleDropdown = () => setDropdownOpen(prevState => !prevState)

  function useOutsideAlerter(ref) {
    React.useEffect(() => {
      function handleClickOutside({ target: { id } }) {
        let tempId = id.toLowerCase().includes("@") ? id.split("@")[0] : id
        if (tempId !== "menu-links" && tempId !== "category-btn") {
          document.getElementById("dropdown-wrapper") &&
            (document.getElementById("dropdown-wrapper").style.display = "none")
        }
      }
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [ref])
  }

  useOutsideAlerter(dropdownRef)

  return (
    <Container>
      <Navbar color="light" fixed="top" light expand="md">
        <Link to="/" className="text-light font-weight-bolder navbar-brand">
          <Image
            src="logo.png"
            style={{
              minWidth: 200,
              height: 40,
            }}
          />
        </Link>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <Link className="nav-link" to="/">
                Home
              </Link>
            </NavItem>
            <NavItem>
              <Link className="nav-link" to="/about-us">
                About Us
              </Link>
            </NavItem>
            <NavItem>
              <Link className="nav-link" to="/contact-us">
                Contact Us
              </Link>
            </NavItem>
          </Nav>
          <Dropdown toggle={toggleDropdown}>
            <DropdownToggle>
              <NavItem className="nav-link list-unstyled dropdown-toggle">
                Categories
              </NavItem>
            </DropdownToggle>
            <div ref={dropdownRef}>
              {dropdownOpen && (
                <div id="dropdown-wrapper" className="menu-dropdown">
                  <Row className="m-0">
                    {edges.map(
                      (
                        {
                          node: {
                            frontmatter: { categoryName, path },
                          },
                        },
                        index
                      ) => (
                        <Col
                          xs={6}
                          md={3}
                          lg={2}
                          className="menu-link-wrapper"
                          key={index}
                        >
                          <Link
                            to={path}
                            className="p-0 m-0 menu-link"
                            id="menu-links"
                          >
                            {categoryName}
                          </Link>
                        </Col>
                      )
                    )}
                    <Col xs={12} md={2} className="menu-link-wrapper">
                      <Link
                        id="menu-links"
                        className="menu-link font-weight-bold"
                        to={"/all-categories"}
                      >
                        All categories
                      </Link>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          </Dropdown>
        </Collapse>
      </Navbar>
    </Container>
  )
}

export default Header
