import React from "react";
// import { Link } from "gatsby"

const FooterEnding = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="footer-headline">
          <i className="far fa-paper-plane"></i>
          <div className="col-sm-6 col-md-6 col-lg-6 copyright">
            <span>
              Copyright © 2021 Vetement corporation. All rights reserved
            </span>
          </div>
          <div className="col-sm-5 col-md-6 col-lg-6 developBy">
            <span>
              Developed by{" "}
              <a href="https://ciphersavvy.com/" className="cipher-savvy-link">
                Edge Work
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterEnding;
